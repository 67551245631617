import { omit } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  FlowUtil,
  IFlow,
  INodeType,
} from '@site-mate/sitemate-flowsite-shared';

import { api } from '@/common/api';
import { NEW_FLOW_ID } from '@/services/node.service';

export interface IFlowQueryParams {
  limit?: number;
  lastFlowPath?: string;
}

const credentialMap = new Map<string, Record<string, string>>();

function getFlows(
  workspaceId: string,
  params?: IFlowQueryParams
): () => Promise<IFlow[]> {
  return async () => {
    const flows = await api.get<IFlow[]>(`workspaces/${workspaceId}/flows`, {
      params,
    });
    return flows;
  };
}

export function useFlows(workspaceId: string, queryParams?: IFlowQueryParams) {
  return useQuery({
    queryKey: ['workspace', workspaceId, 'flows', queryParams],
    queryFn: getFlows(workspaceId, queryParams),
  });
}

/**
 * Perform flow sanitization before sending it to the server based on the node type
 * @param flow
 * @returns flow object with omitted properties
 */
function sanitizeFlow(flow: IFlow) {
  switch (flow.nodes[0].type) {
    case INodeType.POWERBI_CONNECTOR_V1:
    case INodeType.EXCEL_CONNECTOR_V1:
      // to be adjusted once credential update gets supported
      return omit(flow, ['metadata.credentials']);
    case INodeType.XERO_TIMESHEET_V1:
    case INodeType.XERO_INVOICE_V1:
    default:
      return flow;
  }
}

export function useCreateOrUpdateFlow(workspaceId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newFlow: IFlow) => {
      const { _id: flowPath } = newFlow;

      if (flowPath === NEW_FLOW_ID) {
        const { _id: id, ...flowWithoutId } = newFlow;

        const createdFlow = await api.post<IFlow>(
          `workspaces/${workspaceId}/flows`,
          flowWithoutId
        );

        const credentials = newFlow?.metadata?.credentials;
        if (credentials) {
          credentialMap.set(createdFlow._id, newFlow?.metadata?.credentials);
        }

        return createdFlow;
      }

      const flowId = flowPath.split('/').pop();

      return api.patch<IFlow>(
        `workspaces/${workspaceId}/flows/${flowId}`,
        sanitizeFlow(newFlow)
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'flows']),
  });
}

export function useFlowCredentials(flowId: string) {
  return credentialMap.get(flowId);
}

export function useDeleteFlow(workspaceId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (flowPath: string) => {
      const flowId = flowPath.split('/').pop();

      return api.delete<unknown>(`workspaces/${workspaceId}/flows/${flowId}`);
    },
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'flows']),
  });
}
