import clsx from 'clsx';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  IFlow,
  INodeType,
  PathService,
  INodeTypeIntercomMapping,
} from '@site-mate/sitemate-flowsite-shared';

import { Button, TextInput } from '@/components';
import {
  IButtonColor,
  IButtonSize,
} from '@/components/elements/button/Button.variants';
import { AlertModal } from '@/components/elements/modal';
import { useCreateOrUpdateFlow, useDeleteFlow } from '@/pages/flows/hooks';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { nodeService } from '@/services';
import { IFlowEvent, NEW_FLOW_ID } from '@/services/node.service';

import { Action } from './Action';
import { Trigger } from './Trigger';

export enum FlowFormType {
  NEW_FLOW_FORM = 'new-flow-form',
  EDIT_FLOW_FORM = 'edit-flow-form',
}

type FlowProps = {
  flow: IFlow;
  onFlowEvent?: (event: IFlowEvent, flow: IFlow) => void;
  debug?: boolean;
};

export const FlowForm = ({
  flow: flowProps,
  onFlowEvent = () => {},
  debug = false,
}: FlowProps) => {
  const [flow, setFlow] = useState(flowProps);
  const [nodeType, setNodeType] = useState<INodeType>();
  const [helpArticle, setHelpArticle] = useState<string>();
  const flowId = PathService.extractId(flow._id);
  const flowName = useRef(flow.name || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { workspaceId } = useWorkspaceContext();

  const createOrUpdateFlow = useCreateOrUpdateFlow(workspaceId);
  const deleteFlow = useDeleteFlow(workspaceId);

  const getHelpArticle = useCallback(() => {
    if (nodeType) {
      const helpArticleUrl = INodeTypeIntercomMapping[nodeType];
      if (helpArticleUrl) {
        setHelpArticle(helpArticleUrl);
      }
    }
  }, [nodeType]);

  useEffect(() => {
    // TODO: this only supports single node flows, need refactor to support multi node flows
    const { type } = flow.nodes[0];
    setNodeType(type);
    getHelpArticle();
  }, [flow, getHelpArticle]);

  const handleFlowUpdated = useCallback(
    (newFlow: IFlow) => {
      if (flow !== newFlow) {
        setFlow(newFlow);
        createOrUpdateFlow.reset();
      }
    },
    [flow, createOrUpdateFlow]
  );

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      event.preventDefault();

      flowName.current = event.target.value.trimStart();

      setFlow(nodeService.setFlowName(flow, flowName.current.trim()));
    },
    [flow]
  );

  const handleSaveFlow = useCallback(() => {
    const type =
      flow._id === NEW_FLOW_ID ? IFlowEvent.CREATE : IFlowEvent.UPDATE;
    createOrUpdateFlow.mutate(flow, {
      onSuccess: (data) => {
        onFlowEvent(type, data);
      },
    });
  }, [createOrUpdateFlow, flow, onFlowEvent]);

  const showConfirmDialog = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, []);

  const handleDeleteFlow = useCallback(async () => {
    await deleteFlow.mutateAsync(flow._id);
    onFlowEvent(IFlowEvent.DELETE, flow);
    setShowDeleteConfirmation(false);
  }, [deleteFlow, flow, onFlowEvent, setShowDeleteConfirmation]);

  return (
    <div
      data-testid={
        flow.createdAt
          ? FlowFormType.EDIT_FLOW_FORM
          : FlowFormType.NEW_FLOW_FORM
      }
      className="mb-4 rounded-sm-10 bg-white p-sm-30 text-default-text shadow"
    >
      <div className="flex items-start justify-between">
        <div className="mb-2 font-bold">Flow Details</div>
        <Button
          as="a"
          href={helpArticle}
          color={IButtonColor.GREY_1}
          size={IButtonSize.SMALL}
          className={helpArticle ? 'flex-none' : 'hidden'}
        >
          <span className="-mb-[4px] text-xl">
            <i className="cc-icon cc-icon-question" />
          </span>
          <span className="inline-block">Learn more</span>
        </Button>
      </div>
      <div className="text-sm">
        <div className="mb-2 ">Flow ID: {flowId}</div>
        <label htmlFor={`flow-name-input-${flow._id}`}>
          <span>Flow Name: </span>
          <TextInput
            id={`flow-name-input-${flow._id}`}
            dataTestId="flow-name-input"
            className={clsx(
              'w-50',
              !flowName.current ? '!border-default-red ' : ''
            )}
            placeholder="Text..."
            value={flowName.current}
            onChange={handleNameChange}
          />
          <span className="px-2 text-default-red">
            {!flowName.current ? 'Please input a flow name' : ''}
          </span>
        </label>
      </div>
      <Trigger flow={flow} onFlowChange={handleFlowUpdated} />
      <Action flow={flow} onFlowChange={handleFlowUpdated} />
      <div className="space-x-2">
        <Button
          className={createOrUpdateFlow?.isLoading ? 'text-grey-5' : ''}
          onClick={handleSaveFlow}
          disabled={createOrUpdateFlow?.isLoading || !flowName}
        >
          Save
        </Button>
        {flow.createdAt && (
          <Button
            color={IButtonColor.RED}
            onClick={showConfirmDialog}
            data-testid="delete-flow-button"
          >
            Delete
          </Button>
        )}
        {createOrUpdateFlow?.isLoading && <span>Saving</span>}
        {createOrUpdateFlow?.isSuccess && <span>Saved</span>}
        {createOrUpdateFlow?.isError && (
          <span className="text-default-red">Saving failed</span>
        )}
      </div>
      <AlertModal
        open={showDeleteConfirmation}
        confirmButtonProps={{ label: 'Delete', color: IButtonColor.RED }}
        message={`Confirm deleting flow ${flowId || ''}?`}
        onConfirm={handleDeleteFlow}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
      {debug && (
        <div>
          <pre>{JSON.stringify(flow, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
