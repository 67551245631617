import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import Container from 'typedi';

import {
  IConnection,
  IDropdownOption,
  IInvoiceSettings,
  IInvoiceUpdateRule,
  IMappingConfigSet,
  IMultipleSetMappingConfig,
  INodeConfig,
  INodeType,
  IXeroInvoiceFieldMappingConfigKey,
  IntegrationType,
  MappingConfigService,
  MappingConfigType,
} from '@site-mate/sitemate-flowsite-shared';

import { ConnectionSelector } from '@/pages/flows/components/connection-selector/ConnectionSelector';
import {
  FixedMappings,
  MultipleSetMappings,
  OutgoingActionProps,
} from '@/pages/flows/components/flows';
import { InvoiceActionNodeSettings } from '@/pages/flows/components/nodes/shared';
import {
  getSourceKey,
  useTemplate,
  useXeroAccountCodes,
  useXeroTrackingItems,
} from '@/pages/flows/hooks';
import { IRequestParameterItem } from '@/pages/flows/types';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { nodeService } from '@/services';

export const XeroSupportedUpdateRules = [
  IInvoiceUpdateRule.Combine,
  IInvoiceUpdateRule.Overwrite,
  IInvoiceUpdateRule.CreateNew,
];

export const XeroInvoiceActionNode = ({
  flow,
  onFlowChange,
  node,
}: OutgoingActionProps) => {
  const { workspaceId } = useWorkspaceContext();
  const templateId = nodeService.getTemplateId(flow);
  const templateQuery = useTemplate(workspaceId, templateId);
  const template = templateQuery.data;

  const mappingService = Container.get(MappingConfigService);
  const connectionId = node?.connectionId;

  const accountCodesQuery = useXeroAccountCodes(workspaceId, connectionId);
  const trackingItemsQuery = useXeroTrackingItems(workspaceId, connectionId);

  const nodeConfigSettings = nodeService.getNodeSettings<IInvoiceSettings>(
    node.config
  );

  const isInvoice = node.type === INodeType.XERO_INVOICE_V1; // if false, assume bill

  const accountCodeOptions =
    accountCodesQuery?.data
      ?.map((accountCode: IRequestParameterItem) => ({
        id: accountCode.id,
        label: accountCode.value,
        value: accountCode.id,
      }))
      .sort((a: IDropdownOption, b: IDropdownOption) =>
        a.label.localeCompare(b.label)
      ) ?? [];

  const trackingOptions =
    trackingItemsQuery?.data
      ?.map((trackingItem: IRequestParameterItem) => ({
        id: trackingItem.id,
        label: trackingItem.value,
        value: trackingItem.id,
      }))
      .sort((a: IDropdownOption, b: IDropdownOption) =>
        a.label.localeCompare(b.label)
      ) ?? [];

  // ensures one tracking item per dropdown - Xero supports a max of 2 active tracking items
  const firstTrackingDropdownOptions: IDropdownOption[] =
    trackingOptions.length > 0 ? [trackingOptions[0]] : [];
  const secondTrackingDropdownOptions: IDropdownOption[] =
    trackingOptions.length > 1 ? [trackingOptions[1]] : [];

  const handleSetConnection = useCallback(
    (connection: IConnection) => {
      onFlowChange(
        nodeService.setNode(
          flow,
          nodeService.setConnectionId(
            node,
            connection._id,
            getSourceKey(connection)
          )
        )
      );
    },
    [flow, node, onFlowChange]
  );

  const handleInvoiceSettingChange = useCallback(
    (settings: IInvoiceSettings) => {
      const newNode = cloneDeep(node);
      newNode.config.settings = settings;
      onFlowChange(nodeService.setNodeConfig(flow, newNode.config));
    },
    [flow, node, onFlowChange]
  );

  const handleNodeChange = (newNode: INodeConfig) => {
    onFlowChange(nodeService.setNodeConfig(flow, newNode));
  };

  const dashpivotToXeroMappingLabels = {
    source: 'Dashpivot:',
    destination: 'Xero:',
  };

  const lineItemConfigs =
    mappingService.getMappingConfigsForKeyAndType<IMultipleSetMappingConfig>(
      IXeroInvoiceFieldMappingConfigKey.LineItems,
      MappingConfigType.MultipleSet,
      node?.config
    );

  return (
    <div className="pb-2">
      <div className="mb-2 mt-4 font-bold">{`Action: Create ${
        isInvoice ? 'invoice' : 'bill'
      }`}</div>
      <ConnectionSelector
        connectionType={IntegrationType.XERO}
        connectionId={connectionId}
        title="Xero Tenant"
        onChangeConnection={handleSetConnection}
      />
      <p className="my-2 py-1 text-sm font-bold">Population</p>
      <p className="mb-4 text-sm">
        {`To create/edit ${
          isInvoice ? 'invoices' : 'bill'
        }, you will need to define the sources of each
        input.`}
      </p>

      <FixedMappings
        node={node?.config}
        template={template}
        wrappedMappingConfigs={[
          {
            mappingKey: IXeroInvoiceFieldMappingConfigKey.Contact,
            mappingLabel: dashpivotToXeroMappingLabels,
          },
          {
            mappingKey: IXeroInvoiceFieldMappingConfigKey.InvoiceDate,
            mappingLabel: dashpivotToXeroMappingLabels,
          },
        ]}
        onNodeChange={handleNodeChange}
        header="General"
      />

      {lineItemConfigs
        ? lineItemConfigs.map((lineItemConfig) => {
            const handleMappingSetChange = (mappingSet: IMappingConfigSet) => {
              handleNodeChange(
                nodeService.updateMultiSetMappingConfig(
                  node.config,
                  lineItemConfig,
                  mappingSet
                )
              );
            };

            const handleMappingDelete = (mapping: IMappingConfigSet) => {
              handleNodeChange(
                nodeService.deleteMultiSetMappingConfig(
                  node.config,
                  lineItemConfig,
                  mapping
                )
              );
            };

            return (
              <MultipleSetMappings
                key={lineItemConfig._id}
                nodeConfig={node.config}
                lineItemConfig={lineItemConfig}
                onMappingSetChange={handleMappingSetChange}
                onMappingSetDelete={handleMappingDelete}
                onMappingSetCreated={handleNodeChange}
                template={template}
                wrappedMappingConfigs={[
                  {
                    mappingKey: IXeroInvoiceFieldMappingConfigKey.Description,
                    mappingLabel: dashpivotToXeroMappingLabels,
                  },
                  {
                    mappingKey: IXeroInvoiceFieldMappingConfigKey.Quantity,
                    mappingLabel: dashpivotToXeroMappingLabels,
                  },
                  {
                    mappingKey: IXeroInvoiceFieldMappingConfigKey.Amount,
                    mappingLabel: dashpivotToXeroMappingLabels,
                  },
                  {
                    mappingKey: IXeroInvoiceFieldMappingConfigKey.Account,
                    mappingLabel: {
                      source: 'Xero:',
                      destination: 'Xero:',
                    },
                    dropdownOptions: {
                      source: accountCodeOptions,
                    },
                  },
                  {
                    mappingKey:
                      IXeroInvoiceFieldMappingConfigKey.TrackingCategory1,
                    mappingLabel: dashpivotToXeroMappingLabels,
                    dropdownOptions: {
                      destination: firstTrackingDropdownOptions,
                    },
                  },
                  {
                    mappingKey:
                      IXeroInvoiceFieldMappingConfigKey.TrackingCategory2,
                    mappingLabel: dashpivotToXeroMappingLabels,
                    dropdownOptions: {
                      destination: secondTrackingDropdownOptions,
                    },
                  },
                ]}
                header="Line items source"
                buttonLabel="Add line item source"
              />
            );
          })
        : null}

      <div className="mb-2 mt-2">
        <InvoiceActionNodeSettings
          heading={`Editing existing ${isInvoice ? 'invoices' : 'bills'}`}
          description={`If ${
            isInvoice ? 'an invoice' : 'a bill'
          } already exists in the same pay period for the same contact, how should new values be handled?`}
          handlingOptions={XeroSupportedUpdateRules}
          nodeConfigSettings={nodeConfigSettings}
          onNodeSettingsChange={handleInvoiceSettingChange}
        />
      </div>
    </div>
  );
};
