import { Template } from '@site-mate/dashpivot-shared-library';
import {
  IMappingConfigSet,
  ISingleMappingConfig,
} from '@site-mate/sitemate-flowsite-shared';

import { MappingItem } from '@/pages/flows/components/flows/MappingItem';
import { WrappedMappingConfig } from '@/pages/flows/types/components';

type MultiSetMappingTableProps = {
  /** The position of the table in the multi set component (based on index + 1) */
  position: number;
  /** The set of line items */
  lineItemSet: IMappingConfigSet;
  /** The template, if it exists */
  template?: Template;
  /** callback for when a single mapping within the set is changed */
  onSingleMappingChange: (newMapping: ISingleMappingConfig) => void;
  /** The mapping configs to be rendered */
  wrappedMappingConfigs: WrappedMappingConfig[];
  /** callback for when the set of mapping configs is deleted */
  onMappingDelete: (mappingSet: IMappingConfigSet) => void;
  /** optional header to be rendered as a paragraph */
  header: string;
};

export const MultiSetMappingTable = ({
  position,
  lineItemSet,
  template,
  onSingleMappingChange,
  wrappedMappingConfigs,
  onMappingDelete,
  header,
}: MultiSetMappingTableProps) => {
  const { mappings } = lineItemSet;

  return (
    <div className="my-2">
      <div className="flex justify-between text-center items-center my-2 h-[28px]">
        <p className="text-sm text-default-text leading-sm-21">{header}</p>
        {position > 1 ? (
          <button
            className="flex items-center text-default-text leading-sm-21"
            type="button"
            onClick={() => onMappingDelete(lineItemSet)}
          >
            <i
              key="icon"
              className="cc-icon cc-icon-close text-default-red text-xl"
              aria-hidden="true"
            />
            <p className="text-sm hover:underline">Remove</p>
          </button>
        ) : null}
      </div>
      <table className="w-full border-collapse border border-grey-2 text-left">
        <thead className="bg-grey-2 text-sm">
          <tr>
            <th className="border-slate-600 border p-2 w-1/2">Source</th>
            <th className="border-slate-600 border p-2 w-1/2">Destination</th>
          </tr>
        </thead>
        <tbody>
          {mappings.map((mapping) => {
            const mappingConfig = wrappedMappingConfigs.find(
              (wrappedMapping) => wrappedMapping.mappingKey === mapping.key
            );

            if (!mappingConfig)
              return (
                <tr key={mapping._id}>
                  <td
                    className="border-slate-500 space-x-2 border p-2 text-sm "
                    colSpan={2}
                  >
                    Mapping Config Not Found for {`'${mapping.key}'`}
                  </td>
                </tr>
              );

            const { mappingKey, mappingLabel, dropdownOptions } = mappingConfig;

            return (
              <MappingItem
                key={mapping._id}
                mappingConfig={mapping}
                configKey={mappingKey}
                template={template}
                sourceLabel={mappingLabel.source}
                destinationLabel={mappingLabel.destination}
                onMappingChange={onSingleMappingChange}
                dropdownOptions={dropdownOptions}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
