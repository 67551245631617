import { Template } from '@site-mate/dashpivot-shared-library';
import {
  IMappingConfigSet,
  IMultipleSetMappingConfig,
  INodeConfig,
  ISingleMappingConfig,
} from '@site-mate/sitemate-flowsite-shared';

import { Button } from '@/components';
import { IButtonColor } from '@/components/elements/button/Button.variants';
import { MultiSetMappingTable } from '@/pages/flows/components/flows/MultiSetMappingTable';
import { WrappedMappingConfig } from '@/pages/flows/types/components';
import { nodeService } from '@/services';

type MultipleSetMappingsProps = {
  /** The multiple set mapping config */
  nodeConfig: INodeConfig;
  /** The multiple set mapping config */
  lineItemConfig: IMultipleSetMappingConfig;
  /** The template, if it exists */
  template?: Template;
  /** callback for when a mapping set is changed */
  onMappingSetChange: (mappingSet: IMappingConfigSet) => void;
  /** callback for when a mapping set is changed */
  onMappingSetDelete: (mappingSet: IMappingConfigSet) => void;
  /** callback when the a mapping set is created */
  onMappingSetCreated: (newNode: INodeConfig) => void;
  /** The mapping configs to be rendered */
  wrappedMappingConfigs: WrappedMappingConfig[];
  /** optional header to be rendered as a paragraph */
  header: string;
  /** button label for the add new mapping button */
  buttonLabel: string;
};

export const MultipleSetMappings = ({
  nodeConfig,
  lineItemConfig,
  template,
  onMappingSetChange,
  onMappingSetDelete,
  onMappingSetCreated: handleNodeChange,
  wrappedMappingConfigs,
  header,
  buttonLabel,
}: MultipleSetMappingsProps) => {
  const { items: lineItemSets } = lineItemConfig;

  const handleSingleMappingChange =
    (lineItemSet: IMappingConfigSet) => (newMapping: ISingleMappingConfig) => {
      if (!lineItemSets || lineItemSets.length === 0) return;

      const updatedMappingSet = nodeService.updateMappingConfigSet(
        lineItemSet,
        newMapping
      );
      onMappingSetChange(updatedMappingSet);
    };

  const handleAddNewMapping = (mappingsSet: IMultipleSetMappingConfig) => {
    // TODO handle thrown error from addMultiMappingConfigItem here
    handleNodeChange(
      nodeService.addMultiMappingConfigItem(nodeConfig, mappingsSet)
    );
  };

  const itemCount = lineItemSets?.length || 0;

  return (
    <>
      {lineItemSets.map((lineItemSet, index) => (
        <MultiSetMappingTable
          key={lineItemSet._id}
          position={index + 1}
          lineItemSet={lineItemSet}
          template={template}
          onSingleMappingChange={handleSingleMappingChange(lineItemSet)}
          wrappedMappingConfigs={wrappedMappingConfigs}
          header={header}
          onMappingDelete={itemCount > 1 ? onMappingSetDelete : () => {}}
        />
      ))}
      <Button
        color={IButtonColor.BLUE}
        onClick={() => handleAddNewMapping(lineItemConfig)}
      >
        {buttonLabel}
      </Button>
    </>
  );
};
