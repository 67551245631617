import {
  INodeType,
  IntegrationType,
} from '@site-mate/sitemate-flowsite-shared';

import { IntegrationsMap } from '@/hooks';
import { ICreateProps } from '@/pages/flows/types';

import { mappingConfigs } from '../mappings/mappings';

type XeroSettings = {
  timesheetExists?: string;
};

export const generateXeroConfig = (
  createProps: ICreateProps,
  integrationsMap: IntegrationsMap
) => {
  const { nodeType } = createProps;

  const dashpivotId = integrationsMap.get(IntegrationType.DASHPIVOT)?._id;
  const xeroId = integrationsMap.get(IntegrationType.XERO)?._id;

  const config = {
    mappings: mappingConfigs[nodeType](dashpivotId, xeroId),
    settings: {} as XeroSettings,
  };

  if (nodeType === INodeType.XERO_TIMESHEET_V1) {
    config.settings.timesheetExists = undefined;
  }

  return config;
};
