import { useCallback, useState } from 'react';

import {
  DateUtil,
  IInvoiceSettings,
  IInvoiceUpdateRule,
  InvoiceUpdateRuleNameMapping,
  Maybe,
} from '@site-mate/sitemate-flowsite-shared';

import { Combobox, DateInput } from '@/components';
import { Identifiable } from '@/models';
import {
  DayOfMonth,
  DayOfWeek,
  Frequency,
  IRuleOptions,
  useRecurrenceRule,
  useFilterByContainsQuery,
} from '@/pages/flows/hooks';

interface IOption {
  _id: string;
  label: string;
}

type PeriodSegmentDropdownProps = {
  disabled?: boolean;
  label?: string;
  name?: string;
  options: Identifiable[];
  placeholder?: string;
  value?: string;
  onChange?: (option: Identifiable) => void;
};

type InvoiceActionNodeSettingsProps = {
  nodeConfigSettings: IInvoiceSettings;
  onNodeSettingsChange: (settings: IInvoiceSettings) => void;
  heading: string;
  description: string;
  handlingOptions: IInvoiceUpdateRule[];
};

/**
 * Converts an enum to an array of options.
 *
 * @param enumObject - The enum to convert.
 * @returns The array of options.
 */
const convertEnumToArray = (enumObject: Record<string, string>) =>
  Object.entries(enumObject).map<Identifiable>(([, value]) => ({
    _id: String(value),
  }));

/**
 * The supported frequency options for the invoice period.
 */
const supportedFrequencyOptions = [
  Frequency.Weekly,
  Frequency.Fortnightly,
  Frequency.Monthly,
].map((frequency) => ({ _id: frequency }));

/**
 * The default start date for each generated rule.
 * When parsing recurrence rules (rrules), the start date is required to calculate the next/previous occurrence.
 * For processing existing invoices created in the past, Jan 1, 2023, serves as the default start date.
 * This provides a one-year buffer from the initial release of this implementation.
 */
const defaultStartDate = '2023-01-01';

const PeriodSegmentDropdown = ({
  disabled = false,
  label,
  name,
  options,
  placeholder = 'Select...',
  value,
  onChange,
}: PeriodSegmentDropdownProps) => {
  const { setQuery, items: filteredOptions } = useFilterByContainsQuery(
    options,
    '_id'
  );

  const optionValue = value ? { _id: value } : undefined;

  return (
    <div className="flex flex-column items-center space-x-2.5">
      {label && <p>{label}</p>}
      <Combobox
        dataTestId={`${name}-dropdown`}
        disabled={disabled}
        name={name}
        valueKey="_id"
        labelKey="_id"
        value={optionValue}
        options={filteredOptions}
        width="w-57.5"
        placeholder={placeholder}
        hidePlaceholderOption
        onInputChange={setQuery}
        onChange={onChange}
      />
    </div>
  );
};

export const InvoiceActionNodeSettings = ({
  nodeConfigSettings,
  onNodeSettingsChange,
  heading,
  description,
  handlingOptions,
}: InvoiceActionNodeSettingsProps) => {
  const invoiceExistsHandlingOptions = handlingOptions.map<IOption>(
    (value) => ({
      _id: String(value),
      label: InvoiceUpdateRuleNameMapping[value],
    })
  );

  const { setQuery: setHandlingQuery, items: filteredHandlingOptions } =
    useFilterByContainsQuery(invoiceExistsHandlingOptions, 'label');

  const invoiceExistsSetting = invoiceExistsHandlingOptions.find(
    (option) => option._id === nodeConfigSettings?.invoiceExists
  );

  const [frequency, setFrequency] = useState<Maybe<Frequency>>();
  const [dayOfWeek, setDayOfWeek] = useState<Maybe<DayOfWeek>>();
  const [dayOfMonth, setDayOfMonth] = useState<Maybe<DayOfMonth>>();
  const [startDate, setStartDate] = useState<Maybe<string>>('');

  const onParseRuleSuccess = useCallback((rule: IRuleOptions) => {
    setFrequency(rule.frequency);
    setDayOfWeek(rule.dayOfWeek);
    setDayOfMonth(rule.dayOfMonth);

    if (rule.startDate) {
      setStartDate(
        DateUtil.formatDate(
          DateUtil.parseUtcDate(rule.startDate),
          'YYYY-MM-DD',
          true
        )
      );
    }
  }, []);

  const onParseFail = useCallback((error: Error) => {
    // eslint-disable-next-line no-console
    console.error('Failed to parse rule', error);
  }, []);

  const { generateRule } = useRecurrenceRule(
    nodeConfigSettings.invoicePeriod?.rule,
    defaultStartDate,
    onParseRuleSuccess,
    onParseFail
  );

  const disableFrequency =
    !invoiceExistsSetting ||
    invoiceExistsSetting?._id === IInvoiceUpdateRule.CreateNew;
  const showDayOfWeek =
    frequency &&
    [Frequency.Weekly, Frequency.Fortnightly].includes(frequency as Frequency);
  const showStartDate = frequency && frequency === Frequency.Fortnightly;
  const showDateOfMonth = frequency && frequency === Frequency.Monthly;

  const ruleOptions = {
    frequency,
    dayOfWeek,
    startDate,
  };

  const resetPeriodOptions = () => {
    setFrequency(undefined);
    setDayOfWeek(undefined);
    setStartDate(undefined);
    setDayOfMonth(undefined);
  };

  const handleInvoiceExistsSettingChange = (option: IOption) => {
    const updatedInvoiceExists =
      (option._id as IInvoiceUpdateRule) ?? IInvoiceUpdateRule.CreateNew;

    let invoicePeriodRule = nodeConfigSettings.invoicePeriod?.rule;

    if (updatedInvoiceExists === IInvoiceUpdateRule.CreateNew) {
      resetPeriodOptions();
      invoicePeriodRule = undefined;
    }

    onNodeSettingsChange({
      invoiceExists: option._id as IInvoiceUpdateRule,
      invoicePeriod: invoicePeriodRule
        ? { rule: invoicePeriodRule }
        : undefined,
    });
  };

  const handleFrequencyChange = ({ _id }: Identifiable) => {
    setFrequency(_id as Frequency);

    let updatedDateOfMonth: Maybe<DayOfMonth> = dayOfMonth;
    let updatedDayOfWeek = dayOfWeek;

    // Resets the other period options to prevent invalid combinations.
    const updatedStartDate = _id === Frequency.Fortnightly ? '' : undefined;
    setStartDate(updatedStartDate);

    if (![Frequency.Weekly, Frequency.Fortnightly].includes(_id as Frequency)) {
      updatedDayOfWeek = undefined;
      setDayOfWeek(updatedDayOfWeek);
    }

    if (_id !== Frequency.Monthly) {
      updatedDateOfMonth = undefined;
      setDayOfMonth(undefined);
    }

    const rule = generateRule({
      ...ruleOptions,
      frequency: _id as Frequency,
      startDate: updatedStartDate,
      dayOfMonth: updatedDateOfMonth,
      dayOfWeek: updatedDayOfWeek,
    });

    onNodeSettingsChange({
      ...nodeConfigSettings,
      invoicePeriod: rule ? { rule } : undefined,
    });
  };

  const handleDayOfWeekChange = ({ _id }: Identifiable) => {
    setDayOfWeek(_id as DayOfWeek);
    const rule = generateRule({ ...ruleOptions, dayOfWeek: _id as DayOfWeek });

    onNodeSettingsChange({
      ...nodeConfigSettings,
      invoicePeriod: rule ? { rule } : undefined,
    });
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedStartDate = event.target.value;
    setStartDate(updatedStartDate);
    const rule = generateRule({
      ...ruleOptions,
      startDate: updatedStartDate,
    });

    onNodeSettingsChange({
      ...nodeConfigSettings,
      invoicePeriod: rule ? { rule } : undefined,
    });
  };

  const handleDayOfMonthChange = ({ _id }: Identifiable) => {
    setDayOfMonth(_id as DayOfMonth);

    const rule = generateRule({
      ...ruleOptions,
      dayOfWeek: undefined,
      dayOfMonth: _id as DayOfMonth,
    });

    onNodeSettingsChange({
      ...nodeConfigSettings,
      invoicePeriod: rule ? { rule } : undefined,
    });
  };

  return (
    <>
      <p className="font-bold text-sm">{heading}</p>

      <p className="mb-2 text-sm pt-2 text-default-text">{description}</p>

      <Combobox
        dataTestId="handling-dropdown"
        name="handling"
        valueKey="_id"
        labelKey="label"
        value={invoiceExistsSetting}
        options={filteredHandlingOptions}
        placeholder="Select..."
        width="w-57.5"
        capitalize
        onInputChange={setHandlingQuery}
        onChange={handleInvoiceExistsSettingChange}
        disabled={invoiceExistsHandlingOptions.length <= 1}
      />

      <p className="mb-2 text-sm pt-2 text-default-text">Period</p>
      <div className="flex flex-column flex-wrap items-center space-x-2.5 align-content: flex-start gap-y-1">
        <PeriodSegmentDropdown
          disabled={disableFrequency}
          name="frequency"
          options={supportedFrequencyOptions}
          value={frequency}
          onChange={handleFrequencyChange}
        />

        {showDayOfWeek && (
          <PeriodSegmentDropdown
            label="on"
            name="day-of-week"
            options={convertEnumToArray(DayOfWeek)}
            value={dayOfWeek}
            onChange={handleDayOfWeekChange}
          />
        )}

        {showStartDate && (
          <div className="flex flex-column items-center space-x-2.5">
            <p>starting on</p>

            <DateInput
              className="w-57.5 p-1.75"
              dataTestId="start-date-input"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
        )}

        {showDateOfMonth && (
          <PeriodSegmentDropdown
            label="on the"
            name="day-of-month"
            options={convertEnumToArray(DayOfMonth)}
            value={dayOfMonth}
            onChange={handleDayOfMonthChange}
          />
        )}
      </div>
    </>
  );
};
